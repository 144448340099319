<template>
  <div class="about section-vh">
    <b-container>
      <b-row>
        <b-col class="col my-4">
          <div class="text-left">
            <div class="h5 text-center">Информация о мерах в связи с неблагоприятной эпидемиологической обстановкой</div>
            <p class="pt-5">
              Мы не можем быть в стороне от последних событий, происходящих в России и в мире.
              Мы заботимся о здоровье спортсменов и персонала. Поэтому на период, пока ситуация не изменится к лучшему, просим придерживаться правил обозначенных ниже. Кому-то они могут показаться излишними, но они направлены на то, чтобы сохранить главное что у нас есть — жизни. Причем не только наши собственные, но и жизни ваших родных и близких. Просьба серьезно отнестись к нижеприведенным правилам и соблюдать их до особого распоряжения организаторов.
            </p>
            <p>
              Перечень мероприятий, проводимых в отношении участников соревнований, организуемых и проводимых ООО «СИТ», в связи с неблагоприятной эпидемиологической обстановкой.
            </p>
            <p>
              1. Спортсмены, планирующие участие в турнирах, организуемых ООО «СИТ» обязаны регулярно измерять температуру и присылать организаторам, с которыми они контактируют по вопросу участия в турнирах, фотографии показаний термометра. В случае повышения температуры, следует незамедлительно сообщить об этом организаторам. В указанном случае спортсмен снимается с соревнований и обязан обратиться в медицинское учреждение. Дальнейшее участие в турнирах возможно только при предоставлении справки из медицинского учреждения о состоянии здоровья.
            </p>
            <p>
              2. При появлении признаков недомогания, симптомов респираторных заболеваний: кашель, насморк, неприятные ощущения в носоглотке, горле, легких, спортсмен обязан незамедлительно сообщить об этом организаторам. В указанном случае спортсмен снимается с соревнований и обязан обратиться в медицинское учреждение. Дальнейшее участие в турнирах возможно только при предоставлении справки из медицинского учреждения о состоянии здоровья.
            </p>
            <p>
              3. До начала и в ходе проведения спортивных соревнований/мероприятий спортсмены по указанию организаторов обязаны измерять температуру. Отказавшиеся от измерения дисквалифицируются и отстраняются от дальнейшего участия в турнире.
            </p>
            <p>
              4. Спортсмены, посещавшие в последний месяц зарубежные страны, а также контактировавшие с лицами, посещавшими зарубежные страны, включенные в список стран с неблагополучной эпидемиологической обстановкой (Китай, Южная Корея, Япония, Италия, Франция, Германия, США, Иран, Испания, Швейцария и иные впоследствии дополненные страны), который утверждается уполномоченным государственным органом, обязаны сообщить об этом организаторам до начала участия в соревнованиях. Указанные лица снимаются с соревнований на срок не менее 14 (четырнадцати) дней.<br/>
              Спортсмены обязаны дать письменные подтверждения о непосещении вышеуказанных зарубежных стран, а также об отсутствии контактов с людьми, посещавшими такие страны.
            </p>
            <p>
              5. Во время подготовки к соревнованиям и во время отдыха без необходимости не приближаться друг к другу на расстояние ближе 1,5 метров. Во время переодевания в спортивную амуницию соблюдать в раздевалках расстояние между друг другом не менее 1,5 метров. В случае если места недостаточно (с учетом требований, установленных настоящим пунктом), переодевание производить поочередно.<br/>
              Исключить рукопожатия и иные способы физического контакта друг с другом.
            </p>
            <p>
              6. Дополнительно разъяснить спортсменам старше 45 лет риски, связанные с участием в соревнованиях, при имеющейся эпидемиологической обстановки. Решение об участии в соревнованиях, с учетом имеющихся рисков, принимается спортсменами самостоятельно. Согласие спортсмена должно быть оформлено в письменном виде.
            </p>
            <p>
              7. Работникам и спортсменам при проведении соревнований необходимо соблюдать правила личной гигиены: часто мыть руки горячей водой, пользоваться антисептическими жидкостями, пить горячий (свыше 45 градусов) чай.
            </p>
            <p>
              8. Работники ООО «СИТ» обязаны обеспечить соревнования средствами измерения температуры, антисептическими жидкостями; обеспечить регулярное проветривание помещений и их обработку антисептическими средствами. При выявлении признаков недомогания у спортсменов или работников незамедлительно проинформировать об этом медицинские службы.
            </p>
            <p>
              9. В случае выявления фактов сокрытия информации о состоянии здоровья, а также несоблюдения настоящих мероприятий, к спортсмену будут применены санкции, вплоть до пожизненной дисквалификации.
            </p>
            <p>
              10. Перечень мероприятий может быть расширен в соответствии с нормативными актами, издаваемыми органами государственной власти субъектов РФ.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: "Info",
    data() {
      return {}
    },
    metaInfo () {
      return {
        title: "Информация о мерах в связи с неблагоприятной эпидемиологической обстановкой",
        titleTemplate: '%s - EBASKETBALL LIGA PRO',
        meta: [
          {name: 'description', content: ""},
        ]
      }
    }
  }
</script>

<style scoped>

</style>